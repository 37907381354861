import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TimelineStepsToFinance from './components/TimelineStepsToFinance';
import PilotBackground from './components/PilotBackground';
import QuickFlippyRegister from './components/QuickFlippyRegister';

const LandingRegisterWithTimelineSteps = () => (
  <PilotBackground showPlane>
    <Grid
      container
      py={{
        xs: 2,
        md: 8,
      }}
      rowSpacing={2}
      bgcolor={{
        xs: 'fingoWhite.main',
        md: 'inherit',
      }}
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        px={4}
        alignItems="flex-start"
        justifyContent="center"
      >
        <Box
          maxWidth={550}
          mt={{
            md: 4,
          }}
        >
          <Typography variant="h1" paragraph align="left" width="100%">
            <Typography variant="h1" component="span" color="primary">¡Obtén financiamiento</Typography>
            {' '}en menos de 1 día!
          </Typography>
          <Typography variant="h4" paragraph align="left" width="100%">
            En 4 simples pasos:
          </Typography>
          <Box
            width="100%"
            bgcolor="fingoWhite.main"
            borderRadius={4}
            px={2}
            py={4}
          >
            <TimelineStepsToFinance />
          </Box>
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={6}
        px={4}
        justifyContent={{
          xs: 'center',
          md: 'flex-start',
        }}
      >
        <QuickFlippyRegister />
      </Grid>
    </Grid>
  </PilotBackground>
);

export default LandingRegisterWithTimelineSteps;

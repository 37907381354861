import FingoSlider from '@fingo/lib/components/slider/FingoSlider';
import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import isNumber from 'lodash/isNumber';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatMoneyWithSign } from '@fingo/lib/helpers';
import AutoCompleteMasterEntity from '@fingo/lib/components/inputs/AutoCompleteMasterEntity';
import ReCAPTCHA from 'react-google-recaptcha';

const InvoiceSimulatorVariablesCaptcha = ({
  setClient,
  setDebtor,
  amount,
  _setAmount,
  daysToPay,
  _setDaysToPay,
  showSliders,
}) => {
  const [maxAmount, minAmount] = [100000000, 0];
  const [maxDaysToPay, minDaysToPay] = [90, 15];
  const formatLabelMoney = useCallback((slideAmount) => `${formatMoneyWithSign(slideAmount)}`, []);
  const formatLabelDays = useCallback((slideDays) => `${slideDays} días`, []);
  const firstSegmentMaxValue = 10000000;
  const firstSegmentPercentage = 0.75;
  const firstSegmentMaxSlideValue = (maxAmount + minAmount) * firstSegmentPercentage;
  const amountScale = useCallback((value) => {
    if (value === minAmount || value === maxAmount) return value;
    if (value <= firstSegmentMaxSlideValue) {
      const scaledValue = value * (firstSegmentMaxValue / firstSegmentMaxSlideValue);
      return Math.floor(scaledValue);
    }
    const scaledValue = firstSegmentMaxValue
    + (maxAmount - firstSegmentMaxValue) * (
      (value - firstSegmentMaxSlideValue) / (maxAmount - firstSegmentMaxSlideValue)
    );
    return Math.floor(scaledValue);
  }, []);
  const recaptchaRef = React.createRef();
  const onSubmit = () => {
    recaptchaRef.current.execute();
  };

  const amountUnscale = useCallback((scaledValue) => {
    const value = Number(scaledValue || 0);
    if (value === minAmount || value === maxAmount) return value;
    if (value < 100) return value;
    if (value <= firstSegmentMaxValue) {
      const unscaledValue = value * (firstSegmentMaxSlideValue / firstSegmentMaxValue);
      return Math.floor(unscaledValue);
    }
    const unscaledValue = (
      (value - firstSegmentMaxValue) / (maxAmount - firstSegmentMaxValue)
    ) * (maxAmount - firstSegmentMaxSlideValue) + firstSegmentMaxSlideValue;
    return Math.floor(unscaledValue);
  }, []);
  const setAmount = useCallback((event) => {
    const { target: { value } } = event;
    const cleanValue = Number(value.replace(/[^0-9]/g, ''));
    if (isNumber(cleanValue)) {
      _setAmount(Math.min(cleanValue, maxAmount));
    }
  }, []);
  const onReCAPTCHAChange = (captchaCode) => {
    if (!captchaCode) return null;
    return null;
    // Handle the form submission here with captchaCode
  };
  const sliderAmount = useMemo(() => amountUnscale(amount), [amount]);
  const setSliderAmount = useCallback((event) => {
    const { target: { value } } = event;
    const cleanValue = Number(value);
    const scaledValue = amountScale(cleanValue);
    _setAmount(scaledValue);
  }, []);
  const showSliderAmount = useCallback(() => amount, [amount]);
  const setDaysToPay = useCallback((event) => {
    const { value } = event.target;
    if (value === '') {
      _setDaysToPay({ target: { value: 0 } });
    } else {
      _setDaysToPay({ target: { value: parseInt(value, 10) } });
    }
  }, []);
  return (
    <Paper
      elevation={4}
      width="100%"
      sx={{
        height: '100%',
        py: {
          xs: 1,
          md: 2,
          lg: 2,
        },
        px: {
          xs: 1,
          md: 4,
          lg: 6,
        },
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        columnSpacing={2}
        rowSpacing={1}
        height="fit-content"
      >
        <FormControl onSubmit={onSubmit}>
          {/* Your form inputs here */}
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="YOUR_RECAPTCHA_SITE_KEY"
            onChange={onReCAPTCHAChange}
          />
          <button type="submit">Submit</button>
        </FormControl>
        <Grid item xs={12} md={6}>
          <AutoCompleteMasterEntity setEntity={setClient} label="Busque su empresa por nombre o rut" />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoCompleteMasterEntity setEntity={setDebtor} label="Busque la empresa de su cliente por nombre o rut" />
        </Grid>
        <Grid
          item
          xs={12}
          md={showSliders ? 3 : 6}
          mt={{
            xs: 0,
            md: 4,
          }}
        >
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="amount-input">Monto</InputLabel>
            <FilledInput
              id="amount-input"
              onChange={setAmount}
              value={formatLabelMoney(amount)}
              color="background.dark"
              disableUnderline
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid container item xs={12} md={9} mt={4} display={showSliders ? 'inherit' : 'none'}>
          <Box px={2} width="100%">
            <FingoSlider
              aria-label="amount"
              name="invoice-amount"
              valueLabelDisplay="on"
              scale={showSliderAmount}
              valueLabelFormat={formatLabelMoney}
              onChange={setSliderAmount}
              value={sliderAmount}
              max={maxAmount}
              min={minAmount}
              marks={[
                { value: minAmount, label: `$${parseInt(minAmount / 1000000, 10).toString()}` },
                { value: maxAmount, label: `$${parseInt(maxAmount / 1000000, 10).toString()}MM` },
              ]}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={showSliders ? 3 : 6}
          mt={{
            xs: 0,
            md: 4,
          }}
        >
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="date-to-pay-input">Días para pago</InputLabel>
            <FilledInput
              id="date-to-pay-input"
              onChange={setDaysToPay}
              color="background.dark"
              type="number"
              value={Number(daysToPay).toString()}
              disableUnderline
              fullWidth
              min={minDaysToPay}
              max={maxDaysToPay}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={9} mt={4} display={showSliders ? 'inherit' : 'none'}>
          <Box px={2} width="100%">
            <FingoSlider
              aria-label="days-to-pay"
              name="invoice-days-to-pay"
              valueLabelDisplay="on"
              valueLabelFormat={formatLabelDays}
              onChange={setDaysToPay}
              value={daysToPay}
              max={maxDaysToPay}
              min={minDaysToPay}
              marks={[
                { value: minDaysToPay, label: formatLabelDays(minDaysToPay) },
                { value: maxDaysToPay, label: formatLabelDays(maxDaysToPay) },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

InvoiceSimulatorVariablesCaptcha.propTypes = {
  setClient: PropTypes.func.isRequired,
  setDebtor: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  _setAmount: PropTypes.func.isRequired,
  daysToPay: PropTypes.number.isRequired,
  _setDaysToPay: PropTypes.func.isRequired,
  showSliders: PropTypes.bool.isRequired,
};

export default InvoiceSimulatorVariablesCaptcha;

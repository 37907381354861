import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTextFieldInput } from '@fingo/lib/hooks';
import SimulationAmounts from '../simulator/SimulationAmounts';
import InvoiceSimulatorVariables from './components/InvoiceSimulatorVariables';
import PilotBackground from './components/PilotBackground';

const Simulator = () => {
  const [amount, _setAmount] = useState(10000000);
  const [daysToPay, setDaysToPay] = useTextFieldInput(30);
  const [client, setClient] = useState();
  const [debtor, setDebtor] = useState();
  return (
    <PilotBackground showPlane>
      <Stack
        sx={{
          width: '100%',
          py: 1,
          mr: 'auto',
          ml: 'auto',
          px: {
            xs: 1,
            md: 4,
          },
        }}
        spacing={2}
      >
        <Typography align="left" variant="h3" paragraph>
          <Typography component="span" color="primary" variant="h3">Simula</Typography> {' '}
          automáticamente el anticipo de tus facturas.
        </Typography>
        <Typography align="justify" variant="h5" paragraph>
          Usa el siguiente simulador:
        </Typography>
        <Box ml={-4}>
          <Grid container height="100%" columnSpacing={4} rowSpacing={1}>
            <Grid item xs={12} md={7}>
              <InvoiceSimulatorVariables
                setClient={setClient}
                setDebtor={setDebtor}
                amount={amount}
                _setAmount={_setAmount}
                daysToPay={daysToPay}
                _setDaysToPay={setDaysToPay}
                showSliders
              />

            </Grid>
            <Grid item xs={12} md={5}>
              <SimulationAmounts
                client={client}
                debtor={debtor}
                amount={amount}
                days={daysToPay}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </PilotBackground>
  );
};

export default Simulator;

import { FullHeightBox } from '@fingo/lib/components/boxes';
import { WelcomeHeader } from '@fingo/lib/components/headers';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useIsMobile } from '@fingo/lib/hooks';
import ThreeStepRegister from '../register/ThreeStepRegister';

const LandingRegisterTeam = () => {
  const isMobile = useIsMobile();
  const titleComponent = useMemo(() => {
    if (isMobile) {
      return (
        <Box py={4}>
          <Typography variant="h1" width="100%" align="center">
            <Typography variant="h1" color="primary" component="span">Regístrate</Typography>
            {' '} en Fingo
          </Typography>
        </Box>
      );
    }
    return <WelcomeHeader topMessage="Crea tu" headerMessage="Regístrate" bottomMessage="cuenta" />;
  }, [isMobile]);
  return (
    <FullHeightBox
      sx={{
        backgroundImage: 'url(https://d2k677lw6yfmkw.cloudfront.net/team/team.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        backgroundPosition: 'bottom',
      }}
    >
      {titleComponent}
      <Box>
        <Paper
          sx={{
            px: {
              xs: 6,
              md: 8,
            },
            pt: 4,
            pb: 6,
            width: {
              xs: 350,
              md: 450,
            },
            ml: 'auto',
            mr: 'auto',
          }}
        >
          <ThreeStepRegister />
        </Paper>
      </Box>
    </FullHeightBox>
  );
};

export default LandingRegisterTeam;

import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FullHeightBox, WaveFullHeightBox } from '@fingo/lib/components/boxes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import QuickRegisterComponent from './components/QuickRegisterComponent';

const LandingRegisterWithPicture = () => {
  const history = useHistory();
  const onRegisterCompleted = history.replace({ pathname: '/app/home' });
  return (
    <WaveFullHeightBox pt={2}>
      <Typography paragraph variant="h1" width="100%" align="center">
        <Typography color="primary" component="span" variant="h1">
          Regístrate
        </Typography>
        {' '} en Fingo
      </Typography>
      <Grid
        mt={4}
        container
        direction={{
          xs: 'column-reverse',
          md: 'row',
        }}
        maxWidth={800}
        ml="auto"
        mr="auto"
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <FullHeightBox
            sx={{
              backgroundImage: 'url(https://d2k677lw6yfmkw.cloudfront.net/images/trust_girl.jpeg)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto 100%',
              backgroundPosition: 'inherit',
              backgroundPositionX: {
                xs: -100,
                md: 0,
              },
            }}
            height={{
              md: '100%',
              xs: 700,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{ width: '100%' }}
            elevation={4}
          >
            <Box ml="auto" mr="auto" maxWidth={300} py={4}>
              <QuickRegisterComponent onRegisterCompleted={onRegisterCompleted} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </WaveFullHeightBox>
  );
};

export default LandingRegisterWithPicture;

import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FingoLogo } from '@fingo/lib/assets';
import ContactLandingFooter from '@fingo/lib/components/footers/ContactLandingFooter';
import { useIsLogged, useIsMobile } from '@fingo/lib/hooks';
import { isLoggedInQuery } from '@fingo/lib/apollo/cache/localQuery';
import { useHistory } from 'react-router-dom';
import { ChurnTitle, ChurnJoinFingoText } from '../typographys/churn-texts';
import TimelineStepsToEvaluateChurn from './components/TimelineStepsToEvaluateChurn';
import DisplayItemsChurn from './components/DisplayItemsChurn';
import LoginLanding from '../../views/Login/LoginLanding';

const ChurnPromotionCodeBox = () => (
  <Stack
    width={{ xs: 300, md: 450, lg: 550 }}
    spacing={2}
    alignItems={{ xs: 'center', md: 'flex-start' }}
  >
    <Typography color="primary" fontWeight="bold" fontSize={{ xs: 20, lg: 22 }}>
      ¡Regístrate!
    </Typography>
    <Typography
      variant="subtitle"
      fontSize={12}
      lineHeight={1.3}
      textAlign={{ xs: 'center', md: 'left' }}
    >
      Para hacer válido el descuento en tu primera operación, muestra el
      siguiente codigo al ejecutivo que te sera asignado:{' '}
      <Typography
        variant="subtitle"
        component="span"
        color="primary"
        fontWeight="bold"
      >
        FNG099
      </Typography>
    </Typography>
  </Stack>
);

const Churn = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const isLogged = useIsLogged(isLoggedInQuery);

  const handleButtonClick = () => {
    history.push('/app/sales/factoring');
  };

  return (
    <Stack
      spacing={{ xs: 8, md: 12 }}
      justifyContent="center"
      alignItems="center"
      width={1}
      mt={2}
      sx={{ backgroundColor: 'white', zIndex: 1 }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'center', md: 'space-around' }}
        alignItems="center"
        width={1}
        spacing={{ xs: 3, md: 0 }}
      >
        <Stack spacing={6} mt={{ xs: 0, md: 10 }}>
          <ChurnTitle />
          {!isMobile && <ChurnPromotionCodeBox />}
        </Stack>
        <Box align="center" width="350px">
          {!isLogged ? (
            <LoginLanding
              paperSx={{
                borderRadius: '10px',
                maxWidth: '350px',
                padding: '6%',
              }}
              landingTag="Promo-Landing"
            />
          ) : (
            <Button
              id="landing-evaluate-invoices-button"
              onClick={handleButtonClick}
              align="center"
              color="primary"
              variant="contained"
              size="large"
              textAlign="center"
              sx={{
                marginTop: { md: '40%' },
                marginLeft: { md: '40%' },
                padding: { md: '35px' },
              }}
            >
              Ver mis facturas disponibles!
            </Button>
          )}
        </Box>
      </Stack>
      {isMobile && <ChurnPromotionCodeBox />}
      <TimelineStepsToEvaluateChurn isLogged={isLogged} />
      <img
        src={FingoLogo}
        alt="Fingo Logo"
        style={{ maxWidth: isMobile ? '150px' : '300px', alignSelf: 'center' }}
      />
      <ChurnJoinFingoText />
      <DisplayItemsChurn />
      <ContactLandingFooter />
    </Stack>
  );
};

export default Churn;
